import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { numberWithCommas } from '../../utils';
import CartBannerComponent from "./CartBannerComponent";
import ProdectDetailsMain from "./ProdectDetailsMain";
import "./index.css";

const ProdectBanners = () => {

  const {productId}= useParams();

  const [productDetails, setproductDetails] = useState(null);


  useEffect(() => {
    fetch("https://multivendorbackend.ahmedul.com/api/products/get/"+productId)
    .then((response) => response.json())
    .then((data) => setproductDetails(data));
  }, [])
  


  return (
    <>

    {productDetails ? (<>
      <CartBannerComponent title={productDetails.name} />
      <div className="container">
        <ProdectDetailsMain
        // image={productDetails.photo}
          title={productDetails.name}
          price={"৳"+ numberWithCommas(productDetails.price)}
          brand={productDetails.model}
          ProductCode="Product 19"
          Availability="In Stock"
          Quality="0"
          productObj={productDetails}
        />
      </div>
    </>) : (<h1>Loading...</h1>) }
     
    </>
  );
};

export default ProdectBanners;
