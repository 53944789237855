import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../Components/AddToCartModal.css";
import { globalOperations } from "../state/ducs/globalState";
import { numberWithCommas } from "../utils";

function AddToCartModal({ show, close, cartItems }) {
  const latestAddedProduct = cartItems.length
    ? cartItems[0]
    : { name: "no items", photo: "test" };

  let subtotalPrice = 0;
  for (let index = 0; index < cartItems.length; index++) {
    const element = cartItems[index];
    subtotalPrice += parseInt(element.price) * element.quantity;
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => close()}
        dialogClassName="modal-70vw"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Added to cart successfully. What is next?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container pb-5">
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    <img
                      className="img-fluid w-100"
                      src={"https://multivendorbackend.ahmedul.com/assets/images/products/"+latestAddedProduct.photo}
                      alt=""
                    />
                  </div>
                  <div className="col-md-6">
                    <h3>{latestAddedProduct.name}</h3>

                    <h3 className="priceNQuantity">
                      {latestAddedProduct.quantity} X &nbsp;
                      <span>
                        ৳{numberWithCommas(latestAddedProduct.price)}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-3 cart-popup-action">
                <div className=""></div>
                <Link
                  to={"/checkout"}
                  onClick={() => close()}
                  className="btn btn-checkout"
                >
                  {" "}
                  Checkout
                </Link>
                <div className="cart-popup-imgbottom">
                  Order subtotal
                  <span class="previewCartCheckout-price">
                    ৳{numberWithCommas(latestAddedProduct.price)}
                  </span>
                  <span class="cart-popup-total">
                    Your cart contains {cartItems.length} items
                  </span>
                </div>
                <Link
                  to={"/"}
                  onClick={close}
                  class="btn btn-view-cart inverse"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Continue shopping
                </Link>
                <Link
                  to={"/viewPage"}
                  onClick={close}
                  class="btn btn-view-cart inverse"
                >
                  View Cart
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

// render(<AddToCartModal />);

const mapStateToProps = (state) => {
  return {
    show: state.globalState.showAddToCartModal,
    cartItems: state.cart,
  };
};

const mapDispatchToProps = {
  close: globalOperations.closeAddToCartModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartModal);
