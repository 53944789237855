import React, { useEffect, useState } from "react";
import "./index.css";

import NewArrivalsCard from "./NewArrivalsCard";

const NewArrivals = ({ t, sectionRef, homePageSearchedResultProducts }) => {
  const [newArrivalProducts, setnewArrivalProducts] = useState([]);

  useEffect(() => {
    fetch("https://multivendorbackend.ahmedul.com/api/products")
      .then((response) => response.json())
      .then((data) => setnewArrivalProducts(data));
  }, []);

  let productsToShow = <h1>Loading...</h1>;
  let heading = (
    <>
      <h1>{t("NewArrivals")}</h1>
      {/* <ul className="d-flex align-items-center">
        <li>{t("cam")}</li>
        <li>{t("comp")}</li>
        <li>{t("desk")}</li>
        <li>{t("lapnN")}</li>
      </ul> */}
    </>
  );
  if (homePageSearchedResultProducts && homePageSearchedResultProducts.length) {
    productsToShow = homePageSearchedResultProducts.map((product) => (
      <div className="col-md-3">
        <NewArrivalsCard
          productObj={product}
          title={product.name}
          price={`৳${product.price}`}
          image={product.photo}
          t={t}
        />
      </div>
    ));

    heading = (
      <>
        <h1>Search Result</h1>
      </>
    );
  } else if (newArrivalProducts.length) {
    productsToShow = newArrivalProducts.slice(0, 20).map((product) => (
      <div className="col-md-3">
        <NewArrivalsCard
          productObj={product}
          title={product.name}
          price={`৳${product.price}`}
          image={product.photo}
          t={t}
        />
      </div>
    ));
  } else {
    productsToShow = <h1>Loading...</h1>;
  }

  return (
    <div className="container p-4 new-arrivals-container">
      <div className="d-flex align-items-center">{heading}</div>
      <div ref={sectionRef} className="row gy-5">
        {/* {newArrivalProducts.length &&
          newArrivalProducts.map((product) => (
            <div className="col-md-3">
              <NewArrivalsCard
                productObj={product}
                title={product.name}
                price={`৳${product.regular_price}`}
                image={product.imageUrl}
                t={t}
              />
            </div>
          ))} */}
        {productsToShow}
      </div>
    </div>
  );
};

export default NewArrivals;
