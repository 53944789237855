import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logo.jpg";
import { cartOperations } from "../../../state/ducs/cart";
import "./index.css";

const Header = ({
  t,
  cartItems,
  removeFromCart,
  handleSectionRef,
  setHomePageSearchedResultProducts,
}) => {
  const [selectedSCat, setSelectedSCat] = useState("");

  const [categories, setCategories] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    axios
      .get("https://multivendorbackend.ahmedul.com/api/allcategories")
      .then(function (response) {
        // handle success
        console.log(response.data);
        const respData = response.data;
        respData.reverse();
        setCategories(respData);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const onSearchHandler = () => {
    fetch(
      "https://multivendorbackend.ahmedul.com/api/search?name=" + searchQuery
    )
      .then((response) => response.json())
      .then((data) => {
        setHomePageSearchedResultProducts(data.products);
      });
  };

  const navigate = useNavigate();

  const handleSCatChange = (e) => {
    setSelectedSCat(e.target.value);
  };

  let subtotalPrice = 0;
  for (let index = 0; index < cartItems.length; index++) {
    const element = cartItems[index];
    subtotalPrice += parseInt(element.price) * element.quantity;
  }

  const onViewCartHandler = () => {
    navigate("/viewPage");
  };

  const onCheckoutHandler = () => {
    navigate("/checkout");
  };

  const handleSearch = () => {
    onSearchHandler();
    handleSectionRef();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Handle the enter key press event here
      console.log('Enter key pressed!');
      // You can perform any action you want here, for example:
      // submitForm();
      handleSearch();
    }
  };

  return (
    <>
      <div className="container pb-5 px-5">
        <div className="row d-flex align-items-start">
          <div className="col-md-3">
            <Link to={"/"} className="logo">
              <img src={Logo} alt="" srcset="" />
            </Link>
          </div>
          <div className="col-md-6 d-flex mt-2 align-items-center search-section">
            <div className="search-category">
              {/* {t("catAll")} */}
              {/* <i className="fa fa-chevron-down"></i> */}
              <select
                onChange={handleSCatChange}
                className="sCat"
                name="sCat"
                id="sCat"
              >
                <option value="all">{t("catAll")}</option>
                {/*
                <option value="desk">{t("desk")}</option>
                <option value="pc">{t("pc")}</option>
                <option value="mc">{t("mc")}</option>
                <option value="comp">{t("comp")}</option>
                <option value="lapnN">{t("lapnN")}</option>
                <option value="mc">{t("mc")}</option>
                <option value="mice">{t("mice")}</option>
                <option value="mon">{t("mon")}</option>
                <option value="print">{t("print")}</option>
                <option value="scann">{t("scann")}</option>
                <option value="webcam">{t("webcam")}</option>
                <option value="tabs">{t("tabs")}</option>
                <option value="soft">{t("soft")}</option>
                <option value="cam">{t("cam")}</option>
                <option value="tabs">{t("tabs")}</option> */}

                {categories.length &&
                  categories.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
              </select>
            </div>
            <div className="searchField p-2">
              <input
                type="text"
                placeholder={t("searchKeyWord")}
                name="search"
                id="search"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="searchButton" onClick={handleSearch}>
              <i className="fa fa-search"></i>
              {t("search")}
            </div>
          </div>
          <div className="col-md-3">
            <div className="cart-info-wrapper w-50">
              <div className="icon p-2 d-flex justify-content-start align-items-center">
                <i className="fa fa-shopping-cart text-bold"></i>
                <div className="counter">{cartItems.length}</div>
              </div>
              <div className="px-2">
                <h6 className="text-uppercase text-bold">
                  {t("shoppingCart")}
                </h6>
                <h6 className="cart-price text-bold">
                  ৳{subtotalPrice || "0.00"}
                </h6>
              </div>

              <div className="cart-items-container">
                {!cartItems.length ? (
                  <p className="p-3">Your shopping cart is empty</p>
                ) : (
                  <ul>
                    <li className="content-item">
                      <table className="table table-striped mb-1">
                        <tbody>
                          {cartItems.map((product) => (
                            <tr>
                              <td className="text-center size-img-cart">
                                <a className="img-thumb" href="">
                                  <img
                                    // className="img-fluid w-100"
                                    height={60}
                                    width={50}
                                    src={
                                      "https://multivendorbackend.ahmedul.com/assets/images/products/" +
                                      product.photo
                                    }
                                    alt=""
                                  />
                                </a>
                              </td>
                              <td className="text-left">
                                {product.name.substr(0, 7)}
                              </td>
                              <td className="text-right">
                                x{product.quantity}
                              </td>
                              <td className="text-right">
                                ৳{product.price * product.quantity}
                              </td>
                              <td className="text-center">
                                <button
                                  type="button"
                                  className="rmCart btn btn-danger btn-xs "
                                  onClick={() => removeFromCart(product)}
                                >
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <div className="py-1 mt-3 px-2 d-flex justify-content-between clear-fix">
                        <button
                          onClick={onViewCartHandler}
                          className="viewCartButton"
                        >
                          View Cart
                        </button>
                        <button
                          onClick={onCheckoutHandler}
                          className="checkoutButton"
                        >
                          Checkout
                        </button>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="border-bottom border-primary"></div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return { cartItems: state.cart };
};

const mapDispatchToProps = {
  removeFromCart: cartOperations.removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
